<template>
  <div class="about">
    <div class="header">
      <img src="@/assets/logo.png" alt="logo">
      <h1>{{ appName }}</h1>
      <p>{{ appDescription }}</p>
    </div>
    <div class="body">
      <h2>版本历史</h2>
      <table>
        <thead>
        <tr>
          <th>版本号</th>
          <th>更新日期</th>
          <th>更新内容</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>prod.web.1.0.0</td>
            <td>2023-07-03</td>
            <td>WEB端</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      <p>{{ author }}</p>
      <p>{{ year }} © All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import store from '@/store'
const botName = "The World " + store.state.version
export default {
  name: 'About',
  data() {
    return {
      appName: botName,
      appDescription: '一个从QQBOT服务独立出来的业务',
      author: '空城里',
      year: new Date().getFullYear()
    }
  }
}
</script>

<style>
.about {
  width: 800px;
  margin: 0 auto;
  text-align: center;
}

.header {
  margin-top: 50px;
}

.header img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.header p {
  font-size: 18px;
  color: #666;
}

.body {
  margin-bottom: 50px;
}

.body h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.body ul {
  list-style: none;
  margin-left: 20px;
  margin-bottom: 30px;
}

.body li {
  font-size: 18px;
  margin-bottom: 10px;
}

.body table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.body table th,
.body table td {
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.body table th {
  background-color: #f5f5f5;
  font-size: 18px;
  text-align: center;
}

.body table td {
  font-size: 16px;
}

.footer {
  color: #999;
  font-size: 14px;
}
</style>
